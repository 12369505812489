<template>
    <div class="flex items-center justify-center w-full bg-black information">
        <div class="py-4 infor">
            <div class="info_top" v-if="infoData.cpzx">
                <div class="out_box">
                    <div class="font-bold text-left text-white item_title">{{ infoData.cpzx.bottomName }}</div>
                    <div class="item_box">
                        <div class="flex items-center text-left text-white item"
                            v-for="(item, index) in infoData.cpzx.bottomEntityList" :key="index">
                            <!-- <a :href="item.link" class="flex items-center" target="__blank">{{ item.bottomName }}</a> -->
                            <span @click="goPage(item)" class="flex items-center cursor-pointer">{{ item.bottomName
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="out_box">
                    <div class="font-bold text-left text-white item_title">{{ infoData.fwzx.bottomName }}</div>
                    <div class="item_box">
                        <div class="flex items-center text-left text-white item"
                            v-for="(item, index) in infoData.fwzx.bottomEntityList" :key="index">
                            <!-- <a :href="item.link">{{ item.bottomName }}</a> -->
                            <span @click="goPage(item)" class="flex items-center cursor-pointer">{{ item.bottomName
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="out_box">
                    <div class="font-bold text-left text-white item_title">{{ infoData.nrzx.bottomName }}</div>
                    <div class="item_box">
                        <div class="flex items-center text-left text-white item"
                            v-for="(item, index) in infoData.nrzx.bottomEntityList" :key="index">
                            <!-- <a :href="item.link">{{ item.bottomName }}</a> -->
                            <span @click="goPage(item)" class="flex items-center cursor-pointer">{{ item.bottomName
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="out_box">
                    <div class="font-bold text-left text-white item_title">{{ infoData.gyhy.bottomName }}</div>
                    <div class="item_box">
                        <div class="flex items-center text-left text-white item"
                            v-for="(item, index) in infoData.gyhy.bottomEntityList" :key="index">
                            <!-- <a :href="item.link">{{ item.bottomName }}</a> -->
                            <span @click="goPage(item)" class="flex items-center cursor-pointer">{{ item.bottomName
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="out_box">
                    <div class="font-bold text-left text-white item_title">{{ infoData.cyzd.bottomName }}</div>
                    <div class="item_box">
                        <div class="flex items-center text-left text-white item"
                            v-for="(item, index) in infoData.cyzd.bottomEntityList" :key="index">
                            <!-- <a :href="item.link" class="flex items-center">{{ item.bottomName }}</a> -->
                            <span @click="goPage(item)" class="flex items-center cursor-pointer">{{ item.bottomName
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class>
                    <div class="mobile_box mobile_flex_box">
                        <div class="font-bold text-left text-white item_title">{{ infoData.zxzx.bottomName }}</div>
                        <div>
                            <div class="text-left text-white item" v-for="(item, index) in  infoData.zxzx.bottomEntityList"
                                :key="index">
                                <span class>{{ item.bottomName }}：</span>
                                <span class="font-bold">{{ item.content }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="mobile_box">
                        <div class="text-left text-white item_title font-boldv mobile_title">{{ infoData.gywm.bottomName }}
                        </div>
                        <div class="flex flex-wrap py-2 img_box mobile_right" style="max-width:290px">
                            <div :class="(index + 1) % 3 != 0 ? 'mr-2' : ''"
                                v-for="(item, index) in infoData.gywm.bottomEntityList" :key="index"
                                class="mb-2 cursor-pointer" @click="openUrl(item)">
                                <div>
                                    <img :src="item.image" alt />
                                </div>
                                <div class="pt-1 text-center text-white code_text">{{ item.bottomName }}</div>
                            </div>
                            <!-- <div class="text-white">微博</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-8 info_bottom" v-if="infoData.gsmc">
                <div class="text-left">{{ infoData.gsmc.bottomName }}</div>
                <div class="text-left">{{ infoData.gsdz.bottomName }}</div>
                <div class="text-left">{{ infoData.kfrx.bottomName }} | <span @click="goIcp" class="cursor-pointer">{{
                    infoData.icp.bottomName
                }}</span></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            product: [
                { title: "火影T5", url: "" },
                { title: "火影T7", url: "" },
                { title: "火影T9", url: "" }
            ],
            serve: [
                { title: "驱动下载", url: "" },
                { title: "保修查询", url: "" },
                { title: "售后服务", url: "" },
                { title: "在线咨询", url: "" },
                { title: "投诉建议", url: "" }
            ],
            content: [
                { title: "软件下载", url: "" },
                { title: "壁纸下载", url: "" },
                { title: "其他资源", url: "" },
                { title: "产品测试", url: "" },
            ],
            about: [
                { title: "加入火影", url: "" },
                { title: "联系我们", url: "" },
                { title: "企业采购", url: "" },
                { title: "招商合作", url: "" },
            ],
            site: [
                { title: "顺丰官网", url: "" },
                { title: "快递100", url: "" },
                { title: "NVIDIA官网", url: "" },
                { title: "Intel官网", url: "" },
                { title: "AMD官网", url: "" },
            ],
            infoData: {},
            obj: [1, 2, 3, 4, 5, 6, 7, 8]
        };
    },
    created() {
        this.getBottomInfo()
    },

    methods: {
        getBottomInfo() {
            this.$request({
                method: "get",
                url: this.$requestPath.findBottom,
                data: {}
            }).then(res => {
                // console.log("底部信息", res)
                if (res.code == 0) {
                    this.infoData = Object.assign({}, this.infoData, res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: "获取底部信息失败" })
                }
            }).catch(error => {
                console.log("获取底部信息error", error)
            })
        },
        goPage(item) {
            // console.log(item)
            if (item.ifStartCustomize) {
                this.$router.push({
                    path: '/index/custom', query: {
                        bottomId: item.bottomId
                    }
                })
            } else if (item.ifLink) {
                window.open(item.link)
            } else {
                console.log("------", item.link)
                this.$router.push({ path: item.link })
                document.body.scrollTop = document.documentElement.scrollTop = 0
            }
        },
        openUrl(item) {
            window.open(item.link)
        },
        goIcp() {
            window.open(`https://beian.miit.gov.cn`)
            // window.open(`https://www.beianx.cn/search/${this.infoData.icp.bottomName.split("：")[1]}`)
        }
    },
};
</script>

<style lang='scss' scoped>
.information {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;

    .infor {
        width: 100%;
        max-width: var(--max-width);
    }
}

@media screen and (max-width: 640px) {
    .mobile_box {
        display: flex;

        .mobile_title {
            opacity: 0;
        }

        .mobile_right {
            width: calc(100% - 5rem) !important;
        }
    }

    .mobile_flex_box {
        display: flex;
        align-items: center;
    }

    .out_box {
        display: flex;
        margin-bottom: 0.625rem;
    }

    .item_title {
        width: 5rem;
    }

    .item_box {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 80px);
    }

    .item {
        padding-right: 0.625rem;
        font-size: 0.8125rem;
        margin-bottom: 0.25rem;
    }

    img {
        width: 4.375rem;
    }

    .img_box {
        margin-top: 0.625rem;
    }

    .about {
        display: none;
    }

    .info_bottom div {
        font-size: 0.75rem;
        color: #d1d1d1;
        height: 1.5625rem;
        line-height: 1.5625rem;
        text-align: center;
    }

    .code_text {
        font-size: 0.75rem;
    }
}

@media screen and (min-width: 640px) {
    .infor .info_top {
        display: flex;
        justify-content: space-between;

        img {
            width: 5.625rem;
        }

        .item {
            height: 2.5rem;
            line-height: 2.5rem;
            font-size: 0.8125rem;
        }

        .code_text {
            font-size: 0.8125rem;
        }
    }

    .info_bottom div {
        font-size: 0.75rem;
        color: #d1d1d1;
        height: 1.875rem;
        line-height: 1.875rem;
    }
}
</style>