<!--
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2023-02-06 09:07:28
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-03-08 09:26:51
 * @FilePath: /com.huizhouyiren.B0805/src/views/index/Index.vue
 * @Description: 主页
-->

<template>
  <div class="index">
    <Navbar></Navbar>

    <div class="w-full placeholder"></div>

    <div>
      <div style="user-select: none;touch-action: none; "
        class="fixed flex items-center justify-center duration-700 cursor-pointer serve" id="drag" v-show="showInfo">
        <div @click="goServe" class="relative">
          <div class="flex items-center justify-center">
            <!-- <img src="../../assets/images/index/pc/indexpc1.png" alt /> -->
            <img :src="logoPic.customerImage" alt :draggable="false" />
          </div>
          <div class="text-xs">客服</div>
          <div class="absolute text-xs text-white rounded bg-primary" style="top:-20px;right:-20px;padding:2px 6px"
            v-if="unreadFlag">
            news
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
    <InformationBar v-if="showInfo"></InformationBar>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import InformationBar from "./components/InformationBar.vue";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    Navbar,
    InformationBar,
  },
  data() {
    return {
      showInfo: true,
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.$route.path == "/index/sales/chat"
          ? (this.showInfo = false)
          : (this.showInfo = true);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState(["logoPic", 'isMobile']),
    ...mapState("chat", ["unreadFlag"]),

  },
  mounted() {
    if (this.isMobile) {
      this.mobileDrag()
    } else {
      this.pcDrag()
    }
  },
  methods: {
    mobileDrag() {
      //获取元素
      var div = document.getElementById('drag');
      var startX = 0; // 获取手指初始坐标
      var startY = 0;
      var x = 0; // 获得盒子原来的位置
      var y = 0;
      // 手指触摸
      div.addEventListener('touchstart', function (e) {
        //  获取手指初始坐标
        startX = e.targetTouches[0].pageX;
        startY = e.targetTouches[0].pageY;
        x = this.offsetLeft;
        y = this.offsetTop;
        this.style.boxShadow = '0 0 15px rgba(0, 0, 0, .6)';
      });
      // 手指离开
      div.addEventListener('touchend', function (e) {
        this.style.boxShadow = '';
      });

      // 手指按住移动
      div.addEventListener('touchmove', function (e) {
        //  计算手指的移动距离：手指移动之后的坐标减去手指初始的坐标
        var moveX = e.targetTouches[0].pageX - startX;
        var moveY = e.targetTouches[0].pageY - startY;
        // 移动盒子 盒子原来的位置 + 手指移动的距离
        this.style.left = x + moveX + 'px';
        this.style.top = y + moveY + 'px';
        e.preventDefault(); // 阻止屏幕滚动的默认行为
      });
    },
    pcDrag() {
      //获取元素
      var dv = document.getElementById('drag');
      var x = 0;
      var y = 0;
      var l = 0;
      var t = 0;
      var isDown = false;
      //鼠标按下事件
      dv.onmousedown = function (e) {
        //获取x坐标和y坐标
        x = e.clientX;
        y = e.clientY;

        //获取左部和顶部的偏移量
        l = dv.offsetLeft;
        t = dv.offsetTop;
        //开关打开
        isDown = true;
        //设置样式
        dv.style.cursor = 'move';
      }
      //鼠标移动
      document.onmousemove = function (e) {
        if (isDown == false) {
          return;
        }
        //获取x和y
        var nx = e.clientX;
        var ny = e.clientY;
        //计算移动后的左偏移量和顶部的偏移量
        var nl = nx - (x - l);
        var nt = ny - (y - t);
        // 控制左右范围
        if (nl < 0) {
          dv.style.left = '0px';
        } else if (nl > document.body.clientWidth - 85) {
          dv.style.left = document.body.clientWidth - 85 + 'px';
        } else {
          dv.style.left = nl + 'px';
        }
        // 控制上下
        if (nt < 0) {
          dv.style.top = '0px';
        } else if (nt > document.body.clientHeight - 100) {
          dv.style.top = document.body.clientHeight - 100 + 'px';
        } else {
          dv.style.top = nt + 'px';
        }
      }
      //鼠标抬起事件
      document.onmouseup = function () {
        //开关关闭
        isDown = false;
        dv.style.cursor = 'default';
      }
    },
    goServe() {
      // window.open('http://kf.demo.huizhouyiren.com/index/index?code=vbSWPfra3nKF0yYFJIXazW00LzJy4Ffuh%2B2%2BPwu7UwMYbKkkv46UYY5mAizTXAnxkHkL0jlSfTt7Fk7KhIOVyxxfGkEYZzQn3YyffATwokPMI%2FYy')
      // window.open(this.logoPic.customer);
      this.$router.push({ path: "/index/sales/chat" });
    },
  },
};
</script>
<style lang="scss" scoped>
// @media screen and(max-width:640px) {
//     .serve{
//       display: none;
//     }
// }
@media screen and (max-width: 640px) {
  .serve {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 0px 0.625rem 0px rgba(0, 0, 0, 0.1);
    top: 45%;
    right: 0.625rem;
    transform: translateY(-50%);
    z-index: 8;

    img {
      width: 1.5rem;
    }
  }

  .placeholder {
    height: 3.125rem;
  }
}

@media screen and (min-width: 640px) {
  .serve {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 0px 0.625rem 0px rgba(0, 0, 0, 0.1);
    top: 45%;
    right: 0.625rem;
    transform: translateY(-50%);
    z-index: 8;

    img {
      width: 1.5rem;
    }
  }

  .placeholder {
    height: 4.375rem;
  }
}
</style>
