<template>
  <div class="fixed top-0 left-0 right-0 flex items-center justify-center w-full bg-white navbar">
    <div class="relative flex items-center justify-between h-full navbar_inner_box">
      <div @click="goPage({ path: '/index' })" class="flex items-center cursor-pointer logobox">
        <!-- <img
                    class="duration-500 logo"
                    src="../../../assets/images/index/pc/indexpc3.png"
                    alt
                />-->
        <img class="duration-500 logo" :src="getLogo.head" alt />
      </div>
      <div class="pc_show">
        <transition name="menu">
          <div class="flex flex-wrap items-center menu_box" v-if="!isSearch">
            <div v-for="(item, index) in menu" :key="index" @click="goPage(item)"
              class="duration-500 cursor-pointer item hover:text-primary"
              :class="item.path == path ? 'text-primary' : ''">
              <span :class="item.class">{{ item.title }}</span>
            </div>
          </div>
        </transition>
      </div>
      <div class="flex items-center justify-center h-full pc_show">
        <div class="relative flex items-center justify-center h-full cursor-pointer" @mouseenter="isHover = true"
          @mouseleave="isHover = false">
          <img class="mr-4 duration-500 cursor-pointer icon" v-if="isHover"
            src="../../../assets/images/index/pc/user_active.png" alt />
          <img class="mr-4 duration-500 icon" v-else src="../../../assets/images/index/pc/indexpc13.png" alt />
          <transition name="user">
            <div v-if="isHover" class="absolute right-0 z-50 bg-white rounded user_box">
              <div v-if="getLoginStatus" class="flex items-center justify-between px-8 py-3 user_item">
                <div class="text-primary" @click="loginOut">退出登录</div>
              </div>
              <div class="flex items-center justify-between px-8 py-3 user_item" v-else>
                <div class="text-primary" @click="goPage({ path: '/login?pageType=login' })">
                  立即登录
                </div>
                <div>
                  <span class="text-gray-400">无账号？</span>
                  <span @click="goPage({ path: '/login?pageType=register' })">立即注册</span>
                </div>
              </div>
              <div class="px-8 py-3 text-left user_item hover:text-primary" @click="goPage(item)"
                v-for="item in personalTabs" :key="item.path">
                {{ item.title }}
              </div>
              <!-- <div class="px-8 py-3 text-left user_item hover:text-primary">个人中心</div>
                            <div class="px-8 py-3 text-left user_item hover:text-primary">我的收藏</div>
                            <div class="px-8 py-3 text-left user_item hover:text-primary">收货地址</div>
                            <div class="px-8 py-3 text-left user_item hover:text-primary">我的积分</div>-->
            </div>
          </transition>
        </div>
        <div style="width: 1.25em;" class="relative h-full">
          <div class="absolute top-0 right-0 flex items-center h-full bg-white search_box"
            :style="{ width: isSearch ? '22.5rem' : '1.25rem' }">
            <div class="flex items-center rounded search_inner_box" style="height: 1.875rem;">
              <img class="duration-500 icon" src="../../../assets/images/index/pc/indexpc2.png" alt
                @click="showSearch" />
              <div class="input_box">
                <input type="text" placeholder="搜索商品名称" style="width: 100%;background: none;" ref="input"
                  v-model="keyword" @input="searchFunc" />
                <!-- @blur="inputBlur" -->
              </div>
            </div>
          </div>
          <transition name="user">
            <div v-if="searchResult.length > 0"
              class="absolute right-0 z-10 bg-white rounded user_box search_rerult_box">
              <div class="px-8 py-3 text-left cursor-pointer user_item hover:text-primary" v-for="item in searchResult"
                :key="item.commodityId" @click="goLink(item.commodityLinks)">
                {{ item.commodityName }}
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div class="relative flex items-center h-full phone_show">
        <transition name="user">
          <div v-if="isSearch" class="absolute z-10 search_box">
            <div class="flex items-center search_input_box">
              <div class="flex items-center">
                <img class="mr-2 duration-500 icon" src="../../../assets/images/index/pc/indexpc2.png" alt
                  @click="isSearch = false" />
                <input type="text" placeholder="搜索商品名称" class="phone_input" @input="searchFunc" v-model="keyword" />
                <img class="mr-2 duration-500 clear" src="../../../assets/images/index/pc/clear.png" alt />
              </div>
            </div>
            <div class="absolute right-0 w-full bg-white rounded result_box search_rerult_box"
              v-if="searchResult.length > 0">
              <div class="px-8 py-3 text-left cursor-pointer user_item hover:text-primary" v-for="item in searchResult"
                :key="item.commodityId" @click="goLink(item.commodityLinks)">
                {{ item.commodityName }}
              </div>
              <!-- <div
                                class="px-8 py-3 text-left cursor-pointer user_item hover:text-primary"
                            >T7</div>
                            <div
                                class="px-8 py-3 text-left cursor-pointer user_item hover:text-primary"
                            >T9</div>-->
            </div>
          </div>
        </transition>
        <div class="flex items-center justify-center h-full mr-3" v-if="!isSearch">
          <img class="duration-500 icon" src="../../../assets/images/index/pc/indexpc2.png" alt
            @click="showFunc('isSearch')" />
        </div>
        <div class="relative flex items-center justify-center h-full mr-4 cursor-pointer" @click="showFunc('isHover')"
          v-if="!isSearch">
          <img class="duration-500 cursor-pointer icon" v-if="isHover"
            src="../../../assets/images/index/pc/user_active.png" alt />
          <img class="duration-500 icon" v-else src="../../../assets/images/index/pc/indexpc13.png" alt />
          <transition name="user">
            <div v-if="isHover" class="absolute right-0 z-50 bg-white rounded user_box">
              <div v-if="getLoginStatus">
                <div class="py-2 text-center user_item" @click="loginOut">
                  退出登录
                </div>
              </div>
              <div v-else>
                <div class="py-2 text-center user_item" @click="goPage({ path: '/login?pageType=login' })">
                  立即登录
                </div>
                <div class="py-2 text-center user_item" @click="goPage({ path: '/login?pageType=register' })">
                  立即注册
                </div>
              </div>
              <div class="py-2 text-center user_item" v-for="item in personalTabs" :key="item.path"
                @click="goPage(item)">
                {{ item.title }}
              </div>
              <!-- <div class="py-2 text-center user_item">个人中心</div>
                            <div class="py-2 text-center user_item">我的收藏</div>
                            <div class="py-2 text-center user_item">收货地址</div>
                            <div class="py-2 text-center user_item">我的积分</div>-->
            </div>
          </transition>
        </div>
        <div class="relative flex items-center justify-center h-full cursor-pointer" @click="showFunc('showMenu')"
          v-if="!isSearch">
          <img class="duration-500 cursor-pointer icon" v-if="showMenu"
            src="../../../assets/images/index/pc/menu_active.png" alt />
          <img class="duration-500 icon" v-else src="../../../assets/images/index/pc/menu.png" alt />
          <transition name="user">
            <div v-if="showMenu" class="absolute right-0 z-50 bg-white rounded user_box">
              <div class="py-2 text-center user_item" v-for="(item, index) in filterMenu(menu)" :key="index"
                @click="goPage(item)">
                {{ item.title }}
              </div>
              <!-- <div class="py-2 text-center user_item">笔记本</div>
                            <div class="py-2 text-center user_item">鼠标键盘</div>
                            <div class="py-2 text-center user_item">周边配件</div>
                            <div class="py-2 text-center user_item">自助服务</div>
                            <div class="py-2 text-center user_item">品牌</div>
                            <div class="py-2 text-center user_item">内容</div>
                            <div class="py-2 text-center user_item">售后服务</div>-->
            </div>
          </transition>
        </div>
      </div>
      <transition name="navbox">
        <div class="fixed flex items-center justify-center"
          style="width:calc(100vw - 8px);top: 71px;left:0;z-index:1000;">
          <div class="pt-3 pl-2 bg-white rounded navbar_box navbar_click" style="max-width: var(--max-width);"
            v-if="showProduct">
            <div class="flex flex-wrap navbar_click" v-if="productList.length > 0">
              <div style="width:20%;" v-for="item in productList" :key="item.commodityId"
                class="flex items-center justify-center mb-4 navbar_box_item navbar_click" @click.stop="goDetail(item)">
                <div>
                  <div class="flex items-center justify-center" style="width:95%;height: auto;">
                    <img :src="item.masterUrl" alt style="width:95%;height: auto;" />
                  </div>
                  <span class="text-center">{{ item.commodityName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="communityNnavbox">
        <div class="fixed flex items-center justify-center"
          style="width:calc(100vw - 8px);top: 71px;left:0;z-index:1000;">
          <div class="flex flex-wrap bg-white rounded navbar_box" style="max-width: var(--max-width);"
            v-if="showCommunity">
            <div v-for="item in community" :key="item.id" class="p-2 mb-4 navbar_box_item click_community">
              <div class="flex items-center justify-center click_community" style="width:150px;height: 150px;">
                <img :src="item.picUrl" class="click_community" alt style="width:95%;height: auto;" />
              </div>
              <span class="text-center click_community">{{
        item.communityName
      }}</span>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <Popup :isShow="isShow" @close="closePopup" :width="isMobile ? '90vw' : popupWidth">
      <template>
        <div class="relative px-4">
          <div class="absolute top-0 right-0 cursor-pointer" @click="closePopup">
            <img src="../../../assets/images/common/close.png" alt="" style="width: 30px;" />
          </div>
          <div class="py-3 text-center text-gray-700 md:text-xl">售后服务</div>
          <div class="my-3 text-sm text-center text-gray-500">
            售后服务可选择在线人工和自主服务保修两种选择，请根据您的需要
            选择相应的服务模式。
          </div>
          <div class="flex items-center justify-between pb-2">
            <div class="py-1 text-white bg-black cursor-pointer serve_btn" @click="serverClick(1)">
              自主服务保修
            </div>
            <div class="py-1 text-white cursor-pointer bg-primary serve_btn" @click="serverClick(2)">
              在线客服
            </div>
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Popup from "@/components/Popup/Popup";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      isSearch: false,
      isHover: false,
      isFocus: false,
      showMenu: false,
      menu: [
        {
          title: "自助服务",
          path: "/index/serve/index",
          mobileShow: true,
          class: "category",
        },
        {
          title: "品牌",
          path: "/index/brand",
          mobileShow: true,
          class: "category",
        },
        {
          title: "内容",
          path: "/index/content",
          mobileShow: true,
          class: "category",
        },
        //  {
        //     title: "社区",
        //     path: "showCommunity",
        //     mobileShow: false,
        //     class: "community"
        // },
        {
          title: "社区",
          path: "/index/community",
          mobileShow: false,
          class: "community",
        },
        {
          title: "售后服务",
          path: "/index/sales/equipment",
          mobileShow: true,
          class: "category",
        },
      ],
      personalTabs: [
        { title: "个人中心", path: "/index/personal" },
        { title: "我的收藏", path: "/index/personal/collection" },
        { title: "收货地址", path: "/index/personal/address" },
        // { title: "我的积分", path: "/index/personal/integral" }
      ],
      path: "",
      keyword: "",
      searchResult: [], // 搜索的结果
      productList: [],
      showProduct: false,
      showCommunity: false,
      community: [],

      isShow: false,
      popupWidth: "25rem",
    };
  },
  components: {
    Popup,
  },
  created() {
    // console.log(this.$store.getters.getLoginStatus)
    this.getClassification();
    this.showProduct ? this.findPoster() : "";
    this.showCommunity ? this.getCommunity() : "";

    // this.searchDefaultWords()
  },
  watch: {
    $route: function (newVal) {
      this.path = newVal.fullPath;
    },
    isSearch: function () {
      this.isSearch ? "" : (this.searchResult = []);
    },
    // showProduct: function () {
    //     this.showProduct ? this.findPoster() : ''
    // },
    // showCommunity: function () {
    //     this.showCommunity ? this.getCommunity() : ""
    // }
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (
        e.target.className != "product_all" &&
        !e.target.className.includes("navbar_click")
      ) {
        this.showProduct = false;
      }
    });

    document.addEventListener("click", (e) => {
      if (
        e.target.className != "community" &&
        !e.target.className.includes("click_community") &&
        !e.target.className.includes("navbar_box_item")
      ) {
        this.showCommunity = false;
      }
    });
    document.addEventListener("scroll", this.debounce(this.hiddenFunc, 100));
  },
  computed: {
    ...mapState(["isMobile", "userInfo"]),
    ...mapGetters(["getLoginStatus", "getLogo"]),
    ...mapState("chat", ['imUserId'])
  },
  methods: {
    // 售后服务按钮点击
    serverClick(type) {
      this.closePopup();
      if (type == 1) {
        this.$router.push({ path: "/index/warranty/qurey" });
      } else {
        this.$router.push({ path: "/index/sales/chat" });
      }
    },
    // 关闭自主服务选项弹窗
    closePopup() {
      this.isShow = false;
    },
    hiddenFunc() {
      this.showProduct = false;
      this.showCommunity = false;
    },
    showSearch() {
      if (this.isSearch) {
        this.$refs.input.blur();
        this.isFocus = false;
      } else {
        this.isFocus = true;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 600);
      }
      this.isSearch = !this.isSearch;
    },
    // inputBlur(){
    //     this.isFocus = false
    //     this.isSearch = false
    // },
    showFunc(type) {
      let flag = this[type];
      this.isHover = false;
      this.isFocus = false;
      this.isSearch = false;
      this.showMenu = false;
      this[type] = !flag;
    },
    goPage(item) {
      // if (item.permissions) {
      //     if (this.getLoginStatus) {
      //         this.$router.push({ path: item.path })
      //     } else {
      //         this.$message({
      //             message: '请先登录',
      //             type: 'warning'
      //         });
      //         setTimeout(() => {
      //             this.$router.push({ path: '/login' })
      //         }, 1500)

      //     }
      // } else {
      //     this.$router.push({ path: item.path })
      // }
      if (item.path == "/index/sales/equipment") {
        this.isShow = true;
        return;
      }

      if (item.path == "showProduct") {
        if (!this.showProduct) {
          this.findPoster();
        } else {
          this.showProduct = false;
        }
      } else if (item.path == "showCommunity") {
        if (!this.showCommunity) {
          this.getCommunity();
        } else {
          this.showCommunity = false;
        }
      } else {
        this.$router.push({ path: item.path });
      }
      // this.path = item.path
    },
    // 退出登录
    loginOut() {
      let flag = localStorage.getItem("isMobile");
      flag == "true" ? this.mobileLoginOut() : this.pcLoginOut();
    },
    // 手机端退出登录提示
    mobileLoginOut() {
      this.$confirm("确定要退出登录吗？", options)
        .then(() => {
          this.$request({
            url: this.$requestPath.offline,
            method: "post",
            data: { user_id: this.imUserId }
          }).then((res) => {
            console.log('退出登录', res)
          })
          this.$store.commit("updateLoginStatus", false);
          this.$EventBus.$emit("toast", {
            type: "success",
            message: "退出登录成功",
          });
        })
        .catch(() => {
          console.log("点击了关闭");
        });
      let options = {
        title: "", // 默认无标题
        yes: {
          text: "确定",
          style: {
            color: "#DD1821",
          },
        },
        no: {
          text: "取消",
          style: {
            color: "#DD1821",
          },
        },
      };
    },
    // pc端退出登录提示
    pcLoginOut() {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [h("span", null, "确定要退出登录吗？ ")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.$request({
              url: this.$requestPath.offline,
              method: "post",
              data: { user_id: this.imUserId }
            }).then((res) => {
              console.log('退出登录', res)
            })
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "正在退出登录...";
            setTimeout(() => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = "确定";
              done();
              this.$store.commit("updateLoginStatus", false);
            }, 1000);
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$EventBus.$emit("toast", {
            type: "success",
            message: "退出登录成功",
          });
        })
        .catch(() => { });
    },
    // 获取一级分类
    getClassification() {
      let url = this.$requestPath.getCommodityClassify + "?parentId=0";
      this.$request({
        method: "get",
        url,
      })
        .then((res) => {
          // console.log('获取到的一级分类', res)
          if (res.code == 0) {
            this.$store.commit("updatePrimaryClassification", res.data);
            let result = [
              {
                title: "全部产品",
                path: "showProduct",
                mobileShow: false,
                class: "product_all",
              },
            ];
            res.data.forEach((item) => {
              let obj = {
                title: item.categoryName,
                path: `/index/common?code=${item.coding}&parentId=${item.categoryId}`,
                mobileShow: true,
                class: "category",
              };
              result.push(obj);
            });
            let oldMenu = this.menu;
            this.menu = [];
            this.menu.push(...result);
            this.menu.push(...oldMenu);
          } else {
            this.$EventBus.$emit("toast", {
              type: "error",
              message: "获取分类失败",
            });
          }
        })
        .catch((error) => {
          console.log("获取一级分类错误", error);
        });
    },
    // 搜索
    searchFunc() {
      let url = this.$requestPath.search + `?commodityName=${this.keyword}`;
      this.$request({
        method: "get",
        url,
      })
        .then((res) => {
          if (res.code == 0) {
            this.searchResult = [];
            this.searchResult.push(...res.data);
          } else {
            this.$EventBus.$emit("toast", {
              type: "error",
              message: "搜索商品失败",
            });
          }
        })
        .catch((error) => {
          console.log("搜索错误", error);
        });
    },
    // 链接跳转
    goLink(link) {
      window.open(link);
    },
    // 获取全部商品
    findPoster() {
      let url = this.$requestPath.getClassifyProducts;
      this.$request({
        methods: "get",
        url,
        params: {
          classifyId: 0,
        },
      })
        .then((res) => {
          if (res.code == 0) {
            this.productList = [];
            this.productList.push(...res.data);
            if (this.productList.length == 0) {
              this.$EventBus.$emit("toast", {
                type: "warning",
                message: "暂无相关商品",
              });
              return;
            }
            this.showProduct = true;
            console.log(this.productList);
          } else {
            this.$EventBus.$emit("toast", {
              type: "error",
              message: "获取商品失败",
            });
          }
        })
        .catch((error) => {
          console.log("获取的海报", error);
        });
    },
    goDetail(item) {
      this.$router.push({
        path: "/index/activity",
        query: {
          pageType: "product",
          commodityId: item.commodityId,
        },
      });
      this.showProduct = false;
      // window.open(item.commodityLinks)
      // if (item.ifStart) {
      //     window.open(item.commodityLinks)
      // } else {
      //     this.$EventBus.$emit("toast", { type: 'error', message: '商品链接未开启，敬请期待' })
      // }
    },
    // 移动端菜单过滤
    filterMenu(menu) {
      let list = menu.filter((item) => {
        return item.mobileShow;
      });
      return list;
    },
    // 获取社区
    getCommunity() {
      this.$request({
        methods: "get",
        url: this.$requestPath.getCommunity,
        params: {},
      })
        .then((res) => {
          console.log("获取社区", res);
          if (res.code == 0) {
            // this.productList = []
            // this.productList.push(...res.data)
            // console.log(this.productList)
            this.community = [];
            this.community.push(...res.data);
            if (this.community.length == 0) {
              this.$EventBus.$emit("toast", {
                type: "warning",
                message: "暂无相关社区数据",
              });
              return;
            }
            this.showCommunity = true;
          }
        })
        .catch((error) => {
          console.log("获取的海报", error);
        });
    },
    // 获取搜索默认词
    searchDefaultWords() {
      this.$request({
        methods: "get",
        url: this.$requestPath.searchDefaultWords,
        params: {},
      })
        .then((res) => {
          if (res.code == 0) {
            console.log("获取搜索默认词", res);
            // this.productList = []
            // this.productList.push(...res.data)
            // console.log(this.productList)
            // this.community = []
            // this.community.push(...res.data)
            // if (this.community.length == 0) {
            //     this.$EventBus.$emit("toast", { type: 'warning', message: '暂无相关社区数据' })
            //     return
            // }
            // this.showCommunity = true
          }
        })
        .catch((error) => {
          console.log("获取的海报", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 100;
}

.navbar_inner_box {
  padding: 0px 0.9375rem;
}

.logobox {
  height: 100%;
  overflow: hidden;
}

.search_rerult_box {
  max-height: 18.75rem;
  overflow-y: scroll;
}

.navbar_box {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  max-height: 600px;
  overflow-y: scroll;
}

.navbar_box::-webkit-scrollbar {
  width: 0px;
}

@media screen and (max-width: 640px) {
  .serve_btn {
    width: 7.5rem;
  }

  .navbar {
    height: 3.125rem;

    .navbar_inner_box {
      width: 100%;
      border-bottom: 0.0625rem solid var(--borderLightColor);

      .logo {
        width: 5rem;
      }

      .menu_box {
        display: none;
      }

      .icon {
        width: 1rem !important;
      }

      .clear {
        width: 1.4rem;
      }
    }
  }

  .pc_show {
    display: none;
  }

  .phone_input {
    width: 14rem;
  }

  .user_box {
    width: 6.25rem;
    top: 3.125rem;
    box-shadow: 0 0 0.625rem 0.125rem rgba(0, 0, 0, 0.1);
    font-size: 0.75rem;
  }

  .result_box {
    top: 2.8rem;
    box-shadow: 0 0 0.625rem 0.125rem rgba(0, 0, 0, 0.1);
    font-size: 0.75rem;
  }

  .user-enter-active,
  .user-leave-active {
    transition: all 0.6s ease;
  }

  .user-enter,
  .user-leave-to {
    opacity: 0;
  }

  .search_box {
    top: 0.375rem;
    right: 0;
  }

  .search_input_box {
    width: 15.625rem;
    height: 2.1875rem;
    border-radius: 1.25rem;
    background: #e2e4e7;
    padding: 0px 0.625rem;

    input {
      background: none;
    }
  }
}

@media screen and (min-width: 640px) {
  .serve_btn {
    width: 9.375rem;
  }

  .nav_popup {
    width: 31.25rem;
  }

  .phone_show {
    display: none;
  }

  .navbar {
    height: 4.375rem;

    .navbar_inner_box {
      width: 100%;
      max-width: var(--max-width);

      // background: red;
      // border-bottom: 0.0625rem solid #ccc;
      .item {
        display: inline-block;
        padding: 0 0.625rem;
      }

      .logo {
        width: 8.75rem;
      }

      .icon {
        width: 1.25rem;
      }
    }
  }

  .user_box {
    width: 20rem;
    // height: 12.5rem;
    box-shadow: 0 0 0.625rem 0.125rem rgba(0, 0, 0, 0.1);
    top: 4.375rem;
  }

  .user-enter-active,
  .user-leave-active {
    transition: all 0.4s ease;
  }

  .user-enter,
  .user-leave-to {
    transform: translateY(2.5rem);
    opacity: 0;
  }

  .menu-enter-active,
  .menu-leave-active {
    transition: all 0.4s ease;
  }

  .menu-enter,
  .menu-leave-to {
    // transform: translateY(2.5rem);
    opacity: 0;
  }

  .navbox-enter-active,
  .navbox-leave-active {
    transition: all 1s ease;
  }

  .navbox-enter,
  .navbox-leave-to {
    // transform: translateY(150%);
    opacity: 0;
  }

  .search_box {
    width: 1.25rem;
    overflow: hidden;
    transition: 1.5s;

    .input_box {
      width: calc(18.75rem - 3.75rem);
      padding: 0 0.625rem;

      // background: #ccc;
      input {
        background: none;
      }
    }
  }

  .search_active {
    width: 22.5rem;
    //  transition:1.5s;
    // height: 18.75rem;
  }

  .search_rerult_box {
    width: 22.5rem;
  }
}

.user_item:not(:last-child) {
  border-bottom: 0.0625rem solid var(--borderLightColor);
}
</style>
